import { Component, OnDestroy, OnInit } from "@angular/core";
import { SharedService } from "../../shared.service";
import { Lesson, User } from "src/app/shared/models";
import { SharedModule } from "src/app/shared/shared.module";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { LessonSearchService } from "src/app/core/services/lesson-services/lesson-search.service";
import { FavoriteLessonCommonService } from "../../../../core/services/lesson-services/favorite-lesson-common.service";
import { PackageService } from "src/app/core/services/lesson-services/package.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogMessageComponent } from "../../dialogs/dialog-message/dialog-message.component";
import { BulkEditLessonComponent } from "../../dialogs/bulk-edit-lesson/bulk-edit-lesson.component";
import {
  Facet,
  FacetValue,
  SearchFacet,
  SearchOptions
} from "src/app/shared/models/search-options";
import { AppRoles, SearchScopeEnum } from "src/app/config/global-enums.config";
import { ConvertCodeToDescription } from "src/app/shared/pipes/convert-code-to-description";
import { ToastService } from "src/app/core/services/toast.service";
import { SqlServerService } from "src/app/core/http/sql-server.service";
import { AttributeCore } from "src/app/shared/models/configsV2/fieldConfigModel";
import { SearchTutorialService } from "src/app/core/services/search-tutorial.service";
import { CommonService } from "src/app/core/services/lesson-services/common.service";
import { LessonService } from "src/app/core/http/lesson.service";
import { PreviousStateRoutingService } from "src/app/core/services/previous-state-routing.service";
import { ExportCsvService } from "src/app/core/services/lesson-services/export-csv.service";
import { constants } from "src/app/shared/models/constant";
import { UniqueSearchEventModel } from "src/app/shared/models/app-insights";
import { ApplicationInsightsService } from "src/app/core/http/application-insights.service";
import { Subscription } from "rxjs";
import { LessonInput } from "./lessons-table-card/lessons-table-card.component";
import { AthenaSearchService } from "../../../../core/http/athena-search.service";
import { ProjectLookbackService } from "src/app/core/http/project-lookback.service";
import { AddFacetComponent } from "../../dialogs/add-facet/add-facet/add-facet.component";

@Component({
  selector: "app-lessons-list",
  templateUrl: "./lessons-list.component.html",
  styleUrls: ["./lessons-list.component.scss"],
  providers: [SharedModule, ConvertCodeToDescription],
})
export class LessonsListPageComponent implements OnInit, OnDestroy {
  page = 1;
  pageSort: string = "";
  items = 1;
  filterShowMore = new Array(10).fill(false);

  selectedTabIndex = new FormControl(0);
  tabs = [
    { key: "PublishedLessons", label: "published lessons" },
    { key: "ParticipatedLessons", label: "my lessons learned" },
    { key: "AssignedLessons", label: "actions" }
  ];

  pageTitle = "Lessons";
  //Role attribute for R2 release
  role: string = "";
  userBU: string = "";
  facets: Facet[] = []; //UI: Bindings
  searchFacets: SearchFacet[] = [];
  allFacets: Facet[] = [];
  searchOptions: SearchOptions = new SearchOptions();
  importLogs: any = [];
  projectLookbacks: any = [];

  show: boolean = false;
  scope: any = "PublishedLessons";
  searchText: string = "";
  showSearchText: boolean = false;
  sortByoption = [
    { key: "most-recent-scoring-profile", label: "most recent" },
  ];
  itemsPerPage: number = 10;
  itemsPerPageOptions= [
    { key: 10, value: 10},
    { key: 30, value: 30},
    { key: 50, value: 50}
  ]
  removable = true;
  allLessons: LessonInput[] = [];
  currentLessonsSource: "athena" | "ell" = "ell";
  workflowType: string = "Lesson";
  showCards: boolean; //For cards implementation
  fieldConfigCore = new Array<{ key: string; value: AttributeCore }>();
  savedFacets: Facet[] = []; //To get history facets

  public lesson: Lesson;

  public currentUser: User;
  public subscriptions: Subscription = new Subscription();

  constructor(
    private shared: SharedService,
    private lessonService: LessonService,
    private snackBar: MatSnackBar,
    public lessonSearchService: LessonSearchService,
    public athenaSearchService: AthenaSearchService,
    public favoriteLessonCommonService: FavoriteLessonCommonService,
    public dialog: MatDialog,
    public packageService: PackageService,
    public codeToDescription: ConvertCodeToDescription,
    private toastService: ToastService,
    private sqlServerService: SqlServerService,
    public commonService: CommonService,
    public searchTutorial: SearchTutorialService,
    private previousHistory: PreviousStateRoutingService,
    public exportCSVService: ExportCsvService,
    private appInsights: ApplicationInsightsService,
    private projectLookbackService: ProjectLookbackService
  ) {}

  ngOnInit(): void {
    this.loadLocalCaches();
    this.currentUser = JSON.parse(sessionStorage.getItem("profile-ell"));
    this.searchOptions.scope = this.scope;
    this.searchOptions.keyWords = this.searchText;
    this.showSearchText = this.checkKeywordSearchValue(this.searchText);
    this.searchOptions.size = this.itemsPerPage;
    this.searchOptions.userBU = this.userBU;

    this.favoriteLessonCommonService.getFavoriteLessons();
    // To read history data while routing from other pages
    this.setHistoryData();
    this.shared.onClick(3);
    this.searchLessons(true);
  }

  addFacetFilters(){
    const dialogRef = this.dialog.open(AddFacetComponent, {
      width: "500px",
      data: { facets: this.allFacets, fieldConfig: this.fieldConfigCore},
    });
    dialogRef.afterClosed().subscribe((res) => {
      if(res && res.update){
        this.facets.push(res.facet);
        let index = this.fieldConfigCore.findIndex(
          (fieldid) => fieldid.key == res.facet.key
        );
        if(index !== -1){
          this.fieldConfigCore[index].value.beMetadata.showByDefault = true;
        }
      }
    })
  }

  clearAllFacetFilters() {
    for (let facet of this.facets) {
      for (let sel of facet.facetValues.filter((sel) => sel.selected)) {
        sel.selected = false;
      }
    }
    this.searchLessons(true);
  }



  setSearchFilter() {
    this.searchFacets = [];
    for (let facet of this.facets) {
      if (facet.facetValues.some((sel) => sel.selected)) {
        let searchFacet = new SearchFacet();
        searchFacet.key = facet.key;
        searchFacet.value = facet.facetValues
          .filter((i) => i.selected)
          .map((s) => s.value)
          .join(",");

        this.searchFacets.push(searchFacet);
      }
    }
  }

  checkKeywordSearchValue(searchText){
    if(searchText === "" || searchText === null){
      return false;
    }
    else{
      return true;
    }
  }
  

  onItemsPerPageChange() {
    this.page = 1;
    this.searchLessons();
  }

  // Make labels more readable
  convertFacetLabelName(facetName){
    switch(facetName){
      case "area":
        return "Area";
      case "function":
        return "Function";
      case "asset":
        return "Asset";
      case "level2C":
        return "Asset Class";
      case "businessUnit":
        return "Business Unit";
      case "cop":
        return "CoP";
      case "discipline":
        return "Discipline";
      case "primaryImpact":
        return "Primary Impact";
      case "lessonWorkflowType":
        return "Lesson Workflow";
      case "department":
        return "Department";
      case "team":
        return "Team";
      case "relatedProcess":
        return "Related Process";
      case "lookbackCategory":
        return "Lookback Category";
      case "status":
        return "Lesson Status";
      case "subType":
        return "Sub-Type";
      case "type":
        return "Type";
      case "projectCodes":
        return "Project Lookback Name";
      case "isEnterprise":
        return "Shared Enterprise Wide";
      case "importId":
        return "Bulk Import Nickname";
      case "facility":
        return "Facility";
      case "block":
        return "Block";
      case "level5A":
        return "System";
      default:      
        return facetName;
    }
  }
  

  getChips() {
    return this.facets
      .flatMap((facet) => {
        return facet.facetValues;
      })
      .filter((a) => a.selected);
  }

  convertPlbLessonWorkflowType(businessUnit): string{
    switch(businessUnit){
      case 'GOMBU':
        return 'gombuFEWorkflow'
      case 'ABU':
        return 'abuFEWorkflow'
      case 'EBU':
        return 'tcoFEWorkflow'
      case 'BBU':
        return 'bbuFEWorkflow'
      default:
        return 'plbLessonWorkflow'
    }
  }

  verifyFacet(facet: Facet, selectedFilter: FacetValue, event: any) {
    if (facet.key == "importId") {
      if (facet.facetValues.filter((i) => i.selected).length > 1) {
        this.toastService.showError(true, 
          `Sorry! We only allow one import at a time to filter`
        );
        facet.facetValues.find(
          (x) => x.value == selectedFilter.value
        ).selected = false;
        event.currentTarget.checked = false;
      } else {
        this.searchLessons(true);
      }
    } else {
      this.searchLessons(true);
    }
  }

  public logApplicationStatistics(
    searchScope: any,
    searchText: any,
    facets: any
  ) {
    let uniqueSearchModel = new UniqueSearchEventModel();
    uniqueSearchModel.scope = searchScope;
    uniqueSearchModel.searchText = searchText;
    uniqueSearchModel.userFullName = this.currentUser.fullName;
    uniqueSearchModel.userUniqueKey = this.currentUser.uniqueKey;
    uniqueSearchModel.userBusinessUnit = this.currentUser.businessUnit;
    uniqueSearchModel.facets = facets.map((x) => ({ key: x.key, ...x }));
    this.appInsights
      .createUniqueSearchCustomEvent(uniqueSearchModel)
      .subscribe();

      
  }

  // Functionality to distinguish using Athena search versus ELL search
  searchLessons(resetCount: boolean | null = false){
    this.setSearchParameters();


    if ((this.searchOptions.keyWords != "" && this.searchOptions.keyWords != null) || this.searchOptions.SearchFacets.length > 0) {
      this.logApplicationStatistics(this.searchOptions.scope, this.searchOptions.keyWords, this.searchOptions.SearchFacets);
    }

    const useAthenaSearch = (this.searchOptions.keyWords?.length > 0 && this.searchOptions.scope == SearchScopeEnum.PublishedLessons);

    this.toastService.showRetrievingMessage(true, "Please wait a moment as we gather the lessons");
    if(useAthenaSearch){
      this.performAthenaSearch();
    }
    else{
      this.performELLSearch(resetCount);
    }
  }

  // Search Lessons via Athena (external search)
  performAthenaSearch(){


    const sourceMetadataFilters = this.searchOptions.SearchFacets.map(
      (facet) => {
        const facetInfo = this.facets.find((f) => f.key === facet.key);
        if (!facetInfo) {
          console.error("Could not find facet info!", facet);
          return null;
        }

        const facetValueKeys = facet.value.split(",");
        const facetValue = facetInfo.facetValues
          .filter((fv) => facetValueKeys.includes(fv.value))
          .map((fv) => fv.description);
        if (!facetValue) {
          console.error("Could not find facet value!", facet);
        }

        return { [facet.key]: facetValue };
      }
    )
      .filter((f) => f != null)
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    this.athenaSearchService
      .performSearch(this.searchOptions.keyWords, {
        skip: this.searchOptions.skip,
        size: this.searchOptions.size,
        dataSource: "ELL",
        sourceMetadataFilters,
      })
      .subscribe(({ results, totalCount }) => {
        this.items = totalCount;
        this.allLessons = results.map((r) => {
          const sourceMetadataDict: Record<string, string[]> =
            r.document.sourceMetadata.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.name.toLowerCase()]: cur.value,
              }),
              {}
            );

          const getFirst = (i: string[]) =>
            i && i?.length > 0 ? i[0] : "unknown";


          const lesson = {
            id: getFirst(sourceMetadataDict["id"]),
            displayId:
              getFirst(sourceMetadataDict["displayid"]) === "unknown"
                ? getFirst(sourceMetadataDict["id"]) 
                : getFirst(sourceMetadataDict["displayid"]),
            status: "publish", // athena only contains published lessons
            title: getFirst(sourceMetadataDict["title"]),
            lessonWorkflowType: getFirst(sourceMetadataDict["lessonworkflowtype"]),
            description: getFirst(sourceMetadataDict["description"]),
            potentialCause: getFirst(sourceMetadataDict["potentialcause"]),
            recommendation: getFirst(sourceMetadataDict["recommendation"]),
            businessUnit: getFirst(sourceMetadataDict["businessunit"]),
            cop: getFirst(sourceMetadataDict["cop"]),
            discipline: getFirst(sourceMetadataDict["discipline"]),
            region: getFirst(sourceMetadataDict["region"]),
            copTags: sourceMetadataDict["coptags"],
          };

          return lesson;
        });
        this.pageSort = "";
        this.currentLessonsSource = "athena";
        this.snackBar.dismiss();
        this.showCards = true;
        this.show = true;
      });
  }

  // Search Lessons via ELL (local)
  performELLSearch(resetCount: boolean = false){
      this.lessonService
        .searchLessonsFromSql(this.searchOptions)
        .subscribe((response) => {
          if (resetCount) {
            let facets = response.facets;
            response.lessons = response.lessons.filter(lesson => lesson.status !== 'rejected');
            if(
              facets.find((f) => f.key == "projectCodes")?.facetValues.length > 0
            ) {
              this.projectLookbackService.getAllProjectLookbacks().subscribe(result => {
                this.projectLookbacks = result.projectLookbacks;
                this.loadSearchIndexes(facets);
              })
            }


            if (
              facets.find((f) => f.key == "importId")?.facetValues.length > 0
            ) {
              let importIndex = facets.findIndex((x) => x.key == "importId");
              let importLogIds = facets[importIndex].facetValues
                .map((s) => s.value)
                .join(",");
              this.sqlServerService.getImportLogs(importLogIds).subscribe(
                (result) => {
                  this.importLogs = result;
                  this.loadSearchIndexes(facets);
                },
                (error) => this.loadSearchIndexes(facets)
              );
            }
            else {
              this.loadSearchIndexes(facets);
            }
          }

          this.pageSort = "";
          this.allLessons = response.lessons;
          this.currentLessonsSource = "ell";
          this.items = response.recordCount;
          this.showCards = true;
          this.show = true;
          this.snackBar.dismiss();
        });
  }

  // Set search params (e.g. keywords, scope, etc.)
  setSearchParameters() {
    this.setSearchFilter();
    this.searchOptions.scope = this.scope;
    this.searchOptions.keyWords = this.searchText;
    this.showSearchText = this.checkKeywordSearchValue(this.searchText);
    this.searchOptions.size = this.itemsPerPage;
    this.searchOptions.skip = this.itemsPerPage * (this.page - 1);

    this.searchOptions.SearchFacets = this.searchFacets;
    this.searchOptions.scoringProfile = this.pageSort;
  }
  
  clearSearchText() {
    this.searchOptions.keyWords = '';
    this.searchText = '';
    this.searchLessons(true);
    
  }
  setTabPosition() {
    switch (this.scope) {
      case "PublishedLessons":
        this.pageTitle = "Published Lessons";
        this.selectedTabIndex.setValue(0);
        break;
      case "ParticipatedLessons":
        this.pageTitle = "Participated Lessons";
        this.selectedTabIndex.setValue(1);
        break;
      case "AssignedLessons":
        this.pageTitle = "Assigned Lessons";
        this.selectedTabIndex.setValue(2);
        break;
      case "FavoriteLessons":
        this.pageTitle = "Favorite Lessons";
        this.selectedTabIndex.setValue(3);
        break;
      case "ArchivedLessons":
        this.pageTitle = "Archived Lessons";
        this.selectedTabIndex.setValue(4);
        break;
      case "AllLessons":
        this.pageTitle = "All Lessons";
        this.selectedTabIndex.setValue(5);
        break;
    }
  }

  //To change the scope value based on tab selection
  onTabChanged() {
    this.scope = this.tabs[this.selectedTabIndex.value].key;
    switch(this.scope){
      case "PublishedLessons":
        this.pageTitle = "Published Lessons";
        break;
      case "ParticipatedLessons":
        this.pageTitle = "Participated Lessons";
        break;
      case "AssignedLessons":
        this.pageTitle = "Assigned Lessons";
        break;
      case "FavoriteLessons":
        this.pageTitle = "Favorite Lessons";
        break;
      case "ArchivedLessons":
        this.pageTitle = "Archived Lessons";
        break;
      case "AllLessons":
        this.pageTitle = "All Lessons";
        break;
    }
    this.show = false;
    this.itemsPerPage = 10;
    this.clearAllFacetFilters();
    // Loads Cart Lists if any when Favorite Tab is selected.
    this.packageService.getPackageLists();
  }

  sortByOption() {
    if(!this.pageSort) {
      return;
    }
    this.show = false;
    this.allLessons.sort((a, b) => {
        const descA = new Date(a.dateOfSubmission).getTime();
        const descB = new Date(b.dateOfSubmission).getTime();
        return descB - descA;
      })
    this.show = true;
  }

  //Bulk Edit Lesson Implementation
  bulkEditLesson() {
    this.show = false;
    let pageSize = 500;
    let bulkLessons: Lesson[] = [];
    let canBulkEdit: boolean;
    this.searchOptions.size = pageSize;
    this.lessonService
      .searchLessonsFromSql(this.searchOptions)
      .subscribe((response) => {
        bulkLessons = response.lessons;
        let importId = bulkLessons[0].importId;
        if (importId != null) {
          this.canBulkEditBasedOnLessonWorkflowType(bulkLessons);
        } else {
          this.bulkEditDialoguePopup(
            false,
            bulkLessons,
            this.commonService.bulkEditConfig.sameLessonWorkflowType
              .errorMessage
          );
        }

        bulkLessons.forEach((lesson) => {
          //setting default values for crossVisibilityFields for bulk lessons
          if (lesson.sendAsCesComment == null) {
            let crossVisibilityFields: any =
              constants.CrossVisibilityFieldsConst;
            crossVisibilityFields.forEach((i) => {
              this.commonService.resetDefaultVaulesForCrossVisibility(
                i,
                lesson
              );
            });
          }
        });
      });
  }

  canBulkEditBasedOnLessonWorkflowType(bulkLessons: Lesson[]) {
    const lessonWorkflowType = bulkLessons[0].lessonWorkflowType;
    if (
      bulkLessons.every(
        (lesson) => lesson.lessonWorkflowType == lessonWorkflowType
      ) &&
      this.commonService.bulkEditConfig.sameLessonWorkflowType.validation.indexOf(
        lessonWorkflowType
      ) !== -1
    ) {
      const workflowConfig =
        this.commonService.bulkEditConfig[lessonWorkflowType];
      const properties = workflowConfig?.validation ?? [];
      let canBulkEdit = false;
      for (let index = 0; index < properties.length; index++) {
        canBulkEdit = bulkLessons.every(
          (lesson) =>
            lesson[properties[index]] === bulkLessons[0][properties[index]]
        );
        if (!canBulkEdit) break;
      }

      this.bulkEditDialoguePopup(
        canBulkEdit,
        bulkLessons,
        workflowConfig?.errorMessage ?? ""
      );
    } else {
      this.bulkEditDialoguePopup(
        false,
        bulkLessons,
        this.commonService.bulkEditConfig.sameLessonWorkflowType.errorMessage
      );
    }
  }

  bulkEditDialoguePopup(
    canBulkEdit: boolean,
    lessons: Lesson[],
    errorMessage: string
  ) {
    this.show = true;
    if (canBulkEdit == false) {
      const dialogRef = this.dialog.open(DialogMessageComponent, {
        width: "600px",
        data: {
          title: "bulk edit lesson error",
          body: errorMessage,
          hideConfirm: true,
        },
      });
    } else {
      const dialogRef = this.dialog.open(BulkEditLessonComponent, {
        maxWidth: "100vw",
        maxHeight: "100vh",
        height: "100%",
        width: "100%",
        data: { title: "bulk edit lessons", lessons: lessons },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.clearAllFacetFilters();
      });
    }
  }

  public loadLocalCaches() {
    // Tol load attributes from field config core and copconfig, gomFeconfig
    this.commonService.loadConfig();
    this.fieldConfigCore = [].concat(
      ...this.commonService.loadGroups().map((field) => field.value),
      ...this.commonService.loadGroups().map((field) => field.value)
    );

    // Add archive tab if user role is ELL manager R2 Release
    this.role = sessionStorage.getItem("roles");
    this.userBU = sessionStorage.getItem("businessunit");

    if (this.role.split(",").includes(AppRoles.ELLMGR.toString())) {
      this.tabs.push({ key: "ArchivedLessons", label: "archived" });
    }

    // Add all lessons tab if user role is superAdmin
    if (this.role.split(",").includes(AppRoles.SUPERADMINUI.toString())) {
      this.tabs.push({ key: "AllLessons", label: "all lessons" });
    }
  }


  public loadSearchIndexes(facets: Facet[]) {
    this.allFacets = facets; // Set to get all available facets for additional filtering
    if (this.savedFacets?.length <= 0) {
      this.facets = [];
      facets
        .filter((facet) => facet.facetValues.length !== 0)
        .forEach((singleFacet, index) => {
          let facet = new Facet();
          let facetKey = singleFacet.key;
          facet.label = this.fieldConfigCore.find(
            (fieldid) => fieldid.key == facetKey
          )?.value?.feMetadata.formLabel;
  
          let facetMetadata = this.fieldConfigCore.find(
            (fieldid) => fieldid.key == facetKey
          )?.value?.beMetadata;
  
          if ((facetMetadata && facetMetadata.hasOwnProperty('showByDefault'))) {
            facet.key = singleFacet.key;
            let fieldReferenceEnum = this.fieldConfigCore.find(
              (fieldid) => fieldid.key == facetKey
            )?.value?.feMetadata.referenceConfigEnum;
            let referenceConfigValue =
              this.commonService.references[fieldReferenceEnum];
            facet.totalCount = singleFacet.facetValues.length;
            facet.expanded = this.searchFacets.find((f) => f.key === facet.key)
              ? true
              : false;
            facet.facetValues = singleFacet.facetValues.map((facet, i) => ({
              facetLabel: facetKey,
              code: facet.value,
              description: (facet.value == null || facet.value == '' || facet.value == undefined)
                ? 'undefined'
                : facetKey === "importId"
                ? this.importLogs.find((l) => l.id == facet.value)?.importDescription || 'unavailable bulk import id'
                : facetKey === "projectCodes"
                ? this.projectLookbacks.find((x) => x.projectCode == facet.value)?.projectName || 'unavailable project lookback'
                : this.codeToDescription.transform(facet.value, referenceConfigValue),
              selected: this.searchFacets.find(
                (f) => f.value == facet.value
              )
                ? true
                : false,
              facetIndex: index,
              selectedIndex: i,
              count: facet.count,
              value: facet.value,
              longDescription:
                facetKey != "importId"
                  ? ""
                  : this.importLogs.find((l) => l.id == facet.value)
                      ?.longDescription,
            }));
            this.facets.push(facet);
            this.filterShowMore = new Array(this.facets.length).fill(false);
          }
        });
        this.facets.sort((a, b) => a.label.localeCompare(b.label));
    }
    this.savedFacets = []; //empty the history facets after it is utilized.


    // Filter all available facets and remove duplicates with existing or default facets
    this.allFacets = this.allFacets.filter(allFacet => 
      !this.facets.some(facet => facet.key === allFacet.key)
    );
  }
  

  public setHistoryData() {
    if (this.previousHistory.getPreviousUrl()?.includes("/editLesson")) {
      //To read cached filters
      let historyDataFilters = this.previousHistory.getCachedFilters();
      this.savedFacets = this.previousHistory.getCachedFacets();
      if (historyDataFilters != undefined) {
        this.scope = historyDataFilters.scope;
        this.searchText = historyDataFilters.keyWords;
        this.showSearchText = this.checkKeywordSearchValue(this.searchText);
        this.itemsPerPage = historyDataFilters.size;
        this.pageSort = historyDataFilters.scoringProfile;
        this.facets = this.savedFacets;
      }
    } else {
      //To read dashboard search text
      let historyData = history.state.data;
      if (historyData == undefined){
        this.searchText = null;
      } 
      else {
        this.searchText = historyData.searchText;
        this.showSearchText = this.checkKeywordSearchValue(this.searchText);
      }
      this.subscriptions.add(
        this.lessonSearchService.searchTrigger.subscribe((searchParameters) => {
          this.searchText = searchParameters["searchText"];
          this.showSearchText = this.checkKeywordSearchValue(this.searchText);
          this.scope = searchParameters["scope"];
          this.setTabPosition();
          this.searchLessons(true);
        })
      );
    }
    this.scope = this.shared.tab === "tab4" ? "AssignedLessons" : this.scope;
    this.setTabPosition();
  }

  // Export Lessons
  public exportLessonsExcel(data: LessonInput[]) {
    if (this.currentLessonsSource != "ell") {
      return;
    }
    this.exportCSVService.exportToExcel(
      "COMPANY_CONFIDENTIAL_LessonsLearnedExport-" +
        new Date().toLocaleString() +
        ".csv",
      data as Lesson[],
      this.fieldConfigCore
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
