import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Lesson, LessonComment, LessonAttachment } from "src/app/shared/models";
import { LessonSearchResponse, SearchFacet, SearchOptions } from "src/app/shared/models/search-options";
import { LessonOperationResponse } from "src/app/shared/models/lesson-operation-response";
import { CurrentUserService } from "./current-user.service";
import { ProjectLookbackOperationResponse } from "src/app/shared/models/project-lookback-operation-response";

@Injectable({
  providedIn: "root",
})
export class LessonQualityService {
  constructor(private http: HttpClient) { }

  //set root api and its controller in API
  apiRoot = `${environment.apiUrl}LessonQuality`;


  
  rateLesson(body): Observable<any> {
    const endPoint = `${this.apiRoot}/rateLesson`;
    return this.http.post<LessonOperationResponse>(endPoint, body);
  }
}