import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogMessageComponent } from '../../../dialogs/dialog-message/dialog-message.component';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/http/user.service';
import { UserRole } from 'src/app/shared/models/user-role';
import { Manager, TechnicalApprover } from 'src/app/shared/models';
import { DashboardLandingComponent, ParsingType } from '../dashboard-landing/dashboard-landing.component';
import { CoordinatorService } from 'src/app/core/services/lesson-services/coordinator.service';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { UserDisciplineBusinessUnitService } from 'src/app/core/http/user-discipline-bu.service';
import { UserAssetBusinessUnitService } from 'src/app/core/http/user-asset-bu.service';
import { UserTeamBusinessUnitService } from 'src/app/core/http/user-team-bu.service';
import { BusinessUnitService } from 'src/app/core/http/business-unit.service';
import { firstValueFrom, switchMap } from 'rxjs';
import { DisciplineService } from 'src/app/core/http/discipline.service';
import { TeamService } from 'src/app/core/http/team.service';
import { DepartmentService } from 'src/app/core/http/department.service';
import { RelatedProcessService } from 'src/app/core/http/related-process.service';
import { UserDepartmentBusinessUnitService } from 'src/app/core/http/user-department-bu.service';
import { UserRelatedProcessBusinessUnitService } from 'src/app/core/http/user-related-process-bu.service';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent {
  constructor(
    private commonService: CommonService,
    private businessUnitService: BusinessUnitService,
    ) {}
  dataSource : any;
  disciplines: ParsingType[]=[];
  teams: ParsingType[]=[];
  departments: ParsingType[]=[];
  relatedProcesses: ParsingType[]=[];
  assets: ParsingType[]=[];
  cops: ParsingType[]=[];
  regions: ParsingType[]=[];
  businessUnitId: any;

  @Input() set data(data: any) {
    this.dataSource = data;
    
  }
  displayedColumns = ['fullName', 'email', 'discipline', 'team', 'department', 'related process', 'asset', 'cop', 'region'];
  @Input() isDiscipline;
  @Input() isTeam;
  @Input() isRelatedProcess;
  @Input() isDepartment;
  @Input() isAsset;
  @Input() isCop;
  @Input() isRegion;
  @Input() businessUnitCode;


  async ngOnInit() {
    this.disciplines = this.commonService.references['Discipline'];
    this.teams = this.commonService.references['Team'];
    this.departments = this.commonService.references['Department'];
    this.relatedProcesses = this.commonService.references['RelatedProcess'];
    this.assets = this.commonService.references['Asset'];
    this.cops = this.commonService.references['Cop'];
    this.regions = this.commonService.references['CPPRegion'];
    this.businessUnitId = await firstValueFrom(this.businessUnitService.getBusinessUnitId(this.businessUnitCode));
  }
}
