import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HighlightTag } from 'angular-text-input-highlight';
import { ApplicationInsightsService } from 'src/app/core/http/application-insights.service';
import { CommonService } from 'src/app/core/services/lesson-services/common.service';
import { CreateEditSingleLessonService } from 'src/app/core/services/lesson-services/create-edit-single-lesson.service';
import { LessonVotingService } from 'src/app/core/services/lesson-services/lesson-voting-service';
import { ToastService } from 'src/app/core/services/toast.service';
import { SaveOrSubmitEventModel } from 'src/app/shared/models/app-insights';
import { FieldConfigModel } from 'src/app/shared/models/configsV2/fieldConfigModel';
import { ReferenceConfigModel } from 'src/app/shared/models/configsV2/referenceConfigModel';
import { FlawedLesson } from 'src/app/shared/models/flawedLesson';
import { environment } from 'src/environments/environment';
import { DialogMessageComponent } from '../../../dialogs/dialog-message/dialog-message.component';
import { ReportLessonComponent } from '../../../dialogs/report-lesson/report-lesson.component';
import { CreateEditPublishLessonComponent } from '../create-edit-publish-lesson.component';
import { CoordinatorService } from 'src/app/core/services/lesson-services/coordinator.service';
import { EvalconfigService } from 'src/app/core/services/lesson-services/eval-config.service';
import { ProjectLookbackService } from 'src/app/core/http/project-lookback.service';
import { LessonQualityService } from 'src/app/core/http/lesson-quality.service';
import { AddLessonToProjectLookbackComponent } from '../../../dialogs/add-lesson-to-project-lookback/add-lesson-to-project-lookback.component';
import { Lesson } from 'src/app/shared/models';

@Component({
  selector: 'app-create-edit-lesson-form-component',
  templateUrl: './create-edit-lesson-form.component.html',
  styleUrls: ['./create-edit-lesson-form.component.scss']
})
export class CreateEditLessonFormComponent implements OnInit, AfterViewInit {
  @ViewChild("stickyMenu") menuElement: ElementRef;
  private grpEnv = environment.groupEnv;
  sticky = false;
  elementPosition: any;
  referencesData: any;
  referenceConfig = new ReferenceConfigModel();
  fieldConfig = new FieldConfigModel();
  lessonWorkflowStatuses: any;
  lesson: any;
  show: boolean = false;

  pageTitle = "";
  statusTitle = "Lesson Status";

  public showValidateButton = false;
  public showNaughtyWords = false;

  constructor(
    public commonService: CommonService,
    public singleLessonService: CreateEditSingleLessonService,
    public lessonVotingService: LessonVotingService,
    private elementRef: ElementRef,
    private appInsights: ApplicationInsightsService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private toastService: ToastService,
    private coordinatorService: CoordinatorService,
    private evalService: EvalconfigService,
    private projectLookbackService: ProjectLookbackService,
    private lessonQualityService: LessonQualityService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.fieldConfig = JSON.parse(localStorage.getItem("fieldConfig"));
    this.referenceConfig = JSON.parse(localStorage.getItem("referenceConfig"));
    const paramLessonId = this.activatedRoute.snapshot.queryParamMap.get("id");
    if (!paramLessonId)
  
      this.singleLessonService.extensionType =
        this.activatedRoute.snapshot.queryParamMap.get("lessonWorkflowType");

    this.singleLessonService.onInit();


    let workflowName =  this.referenceConfig.core["LessonWorkflowType"].filter((x) => (x.Code == this.singleLessonService.extensionType));
    this.pageTitle = (workflowName.map((item) => item.mapShortname) + ' Workflow');
    
    await this.singleLessonService.generateNewOrEditLessonValue(paramLessonId);

    this.lessonVotingService.getUserLessonVote(
      this.singleLessonService.lesson.id
    );
    this.lessonVotingService.getLessonVoteCount(
      this.singleLessonService.lesson.id
    );
    this.show = true;
  }

  ngAfterViewInit() {
    if (this.menuElement) {
      this.elementPosition = this.menuElement.nativeElement.offsetTop;
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    const windowScroll = window.pageYOffset;
    this.sticky = windowScroll >= 270;
  }

  getTags(item): HighlightTag[] {
    let tags = [];
    if (item.value.feMetadata.highlightWords) {
      const workflowIndex = (
        item.value.feMetadata.highlightWorkflow ?? []
      ).indexOf(this.singleLessonService.lesson.lessonWorkflowType);
      const statusIndex = (item.value.feMetadata.highlightStatus ?? []).indexOf(
        this.singleLessonService.lesson.status
      );
      if (workflowIndex != -1 && statusIndex != -1) {
        this.showValidateButton = true;
        const fieldValue = this.singleLessonService.lesson[item.key];
        if (this.showNaughtyWords && fieldValue) {
          const regexNaughtyWord = new RegExp(
            localStorage.getItem("regexNaughtyWord"),
            "gi"
          );
          let naughtyWord;
          while (
            (naughtyWord = regexNaughtyWord.exec(" " + fieldValue + " "))
          ) {
            tags.push({
              indices: {
                start: naughtyWord.index,
                end: naughtyWord.index + naughtyWord[0].trim().length,
              },
              cssClass: "bg-highlight",
              data: naughtyWord[0].trim(),
            });
          }
          return tags;
        }
      }
    }

    return tags;
  }
    
  async rateLesson(){
    await this.lessonQualityService.rateLesson(this.singleLessonService.lesson).subscribe(res => {
      console.log('res? : ', res);
    });
  }

  saveOrSubmitLesson(isSubmit: boolean, isSave?: boolean) {
    this.removeErrorClass();
    if (isSubmit) {
      this.addErrorClass();
    } else {
      //we need to set the pending status to null so it will not trigger the email when we save the lesson.It should only trigger when we submit
      this.singleLessonService.lesson.pendingStatus = null;
    }
    this.singleLessonService.saveOrSubmitLesson(isSubmit);
    let saveOrSubmitCustomEvent = new SaveOrSubmitEventModel();
    saveOrSubmitCustomEvent.componentName =
      CreateEditPublishLessonComponent.name;
    saveOrSubmitCustomEvent.methodName = this.saveOrSubmitLesson.name;
    this.appInsights
      .createSaveOrSubmitCustomEvent(saveOrSubmitCustomEvent)
      .subscribe();
  }

  addErrorClass() {
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom?.querySelectorAll(".ng-invalid");
    elements?.forEach((control) => {
      control.classList.add("error");
    });
  }

  addLessonToProjectLookback() {
    if(this.singleLessonService.lesson.status == 'publish') {
      this.projectLookbackService.getAllProjectLookbacksForUser().subscribe(res => {
        if(res.projectLookbacks.length > 0){
          const dialogRef = this.dialog.open(AddLessonToProjectLookbackComponent, {
            width: "500px",
            data: { values: res.projectLookbacks },
          });
          dialogRef.afterClosed().subscribe(res => {
            if(res && res.value && res.update){
              if(res.extensionType == this.singleLessonService.lesson.lessonWorkflowType){
                this.singleLessonService.lesson.projectCodes.push(res.value);
                this.singleLessonService.attachLessonToProjectLookback();
              }
              else{
                this.toastService.showError(true, 'Uh-oh! Please ensure that the lesson and project lookback workflows are aligned.')
              }

            }
          })

        }else{
          this.toastService.showWarning(true, 'Looks like we could not find any project lookbacks that you are involved with - please contact your Project Admin for further guidance.');
        }
      });
    }
    else{
      this.toastService.showWarning(true, 'Please ensure the given lesson is published prior to associated it with a project lookback.')
    }
  }


  removeErrorClass() {
    const dom: HTMLElement = this.elementRef.nativeElement;
    const elements = dom?.querySelectorAll(".error");
    elements?.forEach((control) => {
      control.classList.remove("error");
    });
  }

  // Confirm Dialog R2 Release
  public confirmSubmitDialog(selectedAction: any) {

    var body = "";
    //To populate transitionName and pending status for lesson
    this.singleLessonService.lesson.transitionName = selectedAction;
    this.onTransitionNameSelected(selectedAction.Code);

    if(selectedAction.Direction.toLowerCase() === 'forward'){
      body = 'are you sure you want to continue?'
    }
    else{
      body = 'are you sure you want to return to the previous stage?'
    }

    if (this.singleLessonService.lessonFormGroup.valid) {
      const dialogRef = this.dialog.open(DialogMessageComponent, {
        panelClass:"dialog",
        data: { title: "create new lesson", body },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.saveOrSubmitLesson(true);
        }
      });
    } else {
      this.saveOrSubmitLesson(true);
    }
  }

  //Set Pending Status for lesson
  onTransitionNameSelected(selectedTransitionName: any) {
    //change the transitionName and change the pendingStatus accordingly

    if (selectedTransitionName) {
      this.singleLessonService.lesson.pendingStatus =
        this.commonService.getPendingStatus(
          selectedTransitionName,
          this.singleLessonService.lesson.lessonWorkflowType,
          this.singleLessonService.lesson.status
        );
      if (!this.singleLessonService.lesson.pendingStatus)
        this.toastService.showError(true, 
          `Uh-oh! There was an issue in transitioning the lesson. Please reach out to the support team for further assistance."`
        );
    }
  }

  public reportLesson(): void {
    const dialogRef = this.dialog.open(ReportLessonComponent, {
      width: "550px",
      data: {
        reportingTypes:
          this.commonService.referenceConfig.core["ReportingTypes"],
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const flawedLesson = new FlawedLesson();
        flawedLesson.managerActedStatus = false;
        flawedLesson.flawReportingTypeByUser = result.reportType;
        flawedLesson.commentsReportedByUser = result.reportedComments;
        flawedLesson.lessonId = this.singleLessonService.lesson.id;
        flawedLesson.lessonWorkflowType =
          this.singleLessonService.lesson.lessonWorkflowType;
        flawedLesson.reportedByUser =
          this.singleLessonService.userProfile.uniqueKey;

        const referenceConfigGraph =
          this.commonService.fieldConfig.fieldConfig.lessonCore["personasGroup"]
            .manager?.feMetadata?.referenceConfigGraph;

        let workflowCode = this.commonService.convertLessonWorkflowToCode(flawedLesson.lessonWorkflowType);
        const userArr = await this.coordinatorService.getManagersByBu(workflowCode);

        if (userArr.length > 0) {
          flawedLesson.managerActedByUniquekey = userArr[0].uniqueKey;
          await this.singleLessonService.reportLesson(flawedLesson);
          this.router.navigateByUrl("lessons");
        } else {
          this.toastService.showError(true, 'Uh-oh! Looks like there is no valid Manager setup. Please reach out to the support team for further assistance.');
        }
      }
    });
  }
}
