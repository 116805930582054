<!-- Button to open the chat window -->
<div
  class="openChatIcon"
  (click)="toggleChatWindow()"
></div>

<!-- Chat window overlay -->
<div *ngIf="isOpen" class="chatOverlay">
  <div class="chatbotContainer" id="chatWindow">
    <div class="chatbotHeader" id="chatWindowHeader">
      <div class="chatbot-title">E.L.L.A</div>
      <button id="close-button" (click)="closeChatbot()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
    <div class="chatHistoryContainer" id="scrollContainer">
      <div class="chatbotMessage">
        <img src="/assets/icons/Icon - Chatbot.png" alt="Chat Icon" class="chatIcon">
        <p class="disclaimerText">Disclaimer: Please remember that answers provided by this assistance are not to be taken literal. These are simply recommendations based on past lessons learned and proper due diligence should be completed for each unique scenario.</p>
      </div>
      <div class="chatbotMessage">
        <img src="/assets/icons/Icon - Chatbot.png" alt="Chat Icon" class="chatIcon">
        <p class="chatbotText">Hello {{ currentFullUserProfile.name }}! My name's E.L.L.A or Enterprise Lessons Learned Assist! I specialize in all things Lessons Learned.How can I help you today? </p>
      </div>
      <div class="chatHistory">
        <div *ngFor="let comm of chatHistory; index as chatId">
          <div class="userMessage" id="user{{chatId}}">
            <p class="userText"> {{ comm.inputs }} </p>
            <img *ngIf="photoData" [src]="photoData" alt="image" class="userIcon" />
          </div>
          <div class="chatbotMessage">
            <img src="/assets/icons/Icon - Chatbot.png" alt="Chat Icon" class="chatIcon">
            <div class="chatbotText" id="chatbot{{chatId}}" *ngIf="comm.outputs" [innerHtml]="comm.outputs"></div>
          </div>
        </div>
        <div *ngIf="isLoading">
          <div class="userMessage" id="tempUserChat">
            <p class="userText"> {{tempUserChat}} </p>
            <img *ngIf="photoData" [src]="photoData" alt="image" class="userIcon" />
          </div>
          <div class="chatbotMessage" id="tempChatbotSpinner">
            <div class="chatbotTextLoading">
              <img src="/assets/icons/Icon - Chatbot.png" alt="Chat Icon" class="chatIcon">
              <h2>
                <span class="dot">.</span>
                <span class="dot">.</span>
                <span class="dot">.</span>
            </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="samplePromptsContainer">
      <div *ngIf="chatHistory.length === 0">
        <div class="sampleQuestions">
          <button *ngFor="let question of samplePrompts" (click)="sendMessage(question)">
            {{ question }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <form id="input-form">
    <div class="queryContainer">
      <input
        id="input-field"
        type="text"
        placeholder="Got a question? Ask away!"
        [(ngModel)]="query"
        name="query"
      />
      <button id="submit-button" type="submit" (click)="sendMessage(query)">
        <mat-icon class="submit-icon">send</mat-icon>
      </button>
      <button id="new-chat-button" type="reset" (click)="clearChat()">
        <mat-icon>add_comment</mat-icon>
      </button>
    </div>
  </form>
</div>

