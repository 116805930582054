<app-page-header [pageTitle]="pageTitle"></app-page-header>
<div class="selectionContainer" *ngIf="show">
  <!-- BU SELECTION -->
  <div class="guideContainer">
    <h5 (click)="openApproverGuide()"> Need some help? </h5>
  </div>
  <div class="businessUnitContainer" *ngIf="showDashboard">
    <h3>First, please select the workflow you want to configure</h3>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="buSelected" (selectionChange)="buSelectedChange($event.value)" placeholder="Select Business Unit">
        <mat-option *ngFor="let bu of availableBusinessUnits" [value]="bu.Abbreviation">
          {{ bu.mapShortname }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

    <div class="roleContainer" *ngIf="buSelected">
    <h3>Next, please select the role you want to configure</h3>
    <mat-form-field appearance="outline">
      <mat-select [(value)]="roleSelected" (selectionChange)="roleSelectedChange($event.value)" placeholder="Select Role">
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    </div>
  </div>
  <!-- END BU SELECTION -->
    <div class="w-100" *ngIf="buSelected && roleSelected && !isLoading">
      <div
        class="d-flex justify-content-center align-items-center"
        style="min-height: 200px"
      >
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <div class="legalApproverTable" *ngIf="showLegalApprovers">
      <div>
        <div>
          <div class="d-flex flex-row" style="gap: 2rem">
            <button
              type="button"
              class="btn btn-primary addAdmin"
              (click)="addNewLegalApprover(this.regions)"
            >
              + new legal approver
            </button>
            <button
            type="button"
            class="btn btn-primary removeAdmin"
            (click)="removeLegalApprover()"
            *ngIf="LegalApprovers.length > 0"
          >
            - legal approver
          </button>
          </div>
          <div class="mt-4 legalApproverList" *ngIf="isLoading" >
            <app-user-table [data]="LegalApprovers" [isDiscipline]="false">
            </app-user-table>
          </div>
        </div>
      </div>
      </div>

    <div class="ecApproverTable" *ngIf="showEcApprovers">
      <div>
        <div>
          <div class="d-flex flex-row" style="gap: 2rem">
            <button
              type="button"
              class="btn btn-primary addAdmin"
              (click)="addNewEcApprover(this.regions)"
            >
              + new ec approver
            </button>
            <button
            type="button"
            class="btn btn-primary removeAdmin"
            (click)="removeEcApprover()"
            *ngIf="EcApprovers.length > 0"
          >
            - ec approver
          </button>
          </div>
          <div class="mt-4 ecApproverList" *ngIf="isLoading && showEcTable" >
            <app-user-table [data]="EcApprovers" [isRegion]="validateApprovers(EcApprovers)">
            </app-user-table>
          </div>
        </div>
      </div>
      </div>

    <div class="managerTable" *ngIf="showManagers">
      <div>
        <div>
          <div class="d-flex flex-row" style="gap: 2rem">
            <button
              type="button"
              class="btn btn-primary addAdmin"
              (click)="addNewManager()"
            >
              + new manager
            </button>
            <button
            type="button"
            class="btn btn-primary removeManager"
            (click)="removeManager()"
            *ngIf="Managers.length > 0"
          >
            - manager
          </button>
          </div>
          <div class="mt-4 managerList" *ngIf="isLoading" >
            <app-user-table [data]="Managers" [isDiscipline]="false">
            </app-user-table>
          </div>
        </div>
      </div>
      </div>

      <div class="technicalApproverHeader" *ngIf="showTechnicalApprovers && isLoading">
        <mat-tab-group
          class="remove-border-bottom"
          [selectedIndex]="selectedTabIndex.value"
          (selectedIndexChange)="selectedTabIndex.setValue($event)"
          (selectedTabChange)="onTabChanged()"
        >
          <ng-container *ngFor="let tab of tabs; let index = index">
            <ng-container *ngIf="tab.show">
              <mat-tab [label]="tab.label">
              </mat-tab>
            </ng-container>
          </ng-container>
        </mat-tab-group>
      </div>
      

      <div class="technicalApproverTable" *ngIf="showTechnicalApprovers && isLoading">
      
      <!-- Discipline Container -->
      <div class="disciplineTechnicalApproversContainer" *ngIf="showDiscTechnicalApprovers">
      <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
        <div class="w-100" *ngIf="!showDiscTable && businessUnitDiscs.length > 0 && discTechnicalApprovers.length != 0">
          <div
            class="d-flex justify-content-center align-items-center"
            style="min-height: 200px"
          >
            <mat-spinner></mat-spinner>
          </div>
        </div>
        <div class="techAprHeader" *ngIf="businessUnitDiscs.length > 0 && showDiscTable && discTechnicalApprovers.length > 0">
          <button
            type="button"
            class="btn btn-primary addAdmin"
            (click)="addNewTechAppr(this.businessUnitDiscs)"
          >
            + technical approver
          </button>

          <button
          type="button"
          class="btn btn-primary removeAdmin"
          (click)="removeTechnicalApprover('discipline')"
          *ngIf="discTechnicalApprovers.length > 0"
        >
          - technical approver
        </button>
        </div>
        <div class="errorMessage" *ngIf="businessUnitDiscs.length == 0">
          <h3> Oops! Looks like there are no disciplines setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
        </div>
        </div>
      <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="discTechnicalApprovers.length > 0 && showDiscTable">
        <app-user-table [data]="discTechnicalApprovers" [isDiscipline]="validateApprovers(discTechnicalApprovers)" [businessUnitCode]="buSelected">
        </app-user-table>
      </div>
      <div class="noApproversContainer"  *ngIf="discTechnicalApprovers.length == 0 && businessUnitDiscs.length > 0 && showEmptyMessage">
        <div class="noApproversLabel">
          <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
        </div>
        <div class="noApproversButton">
          <button
          type="button"
          class="btn btn-primary"
          (click)="addNewTechAppr(this.businessUnitDiscs)"
        >
          + technical approver
        </button>
        </div>
      </div>
    </div>

    <!-- Department Container -->
    <div class="departmentTechnicalApproversContainer" *ngIf="showDepartmentTechnicalApprovers">
    <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
      <div class="w-100" *ngIf="!showDepartmentTable && businessUnitDepartments.length > 0  && departmentTechnicalApprovers.length != 0">
        <div
          class="d-flex justify-content-center align-items-center"
          style="min-height: 200px"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <div class="techAprHeader" *ngIf="businessUnitDepartments.length > 0 && showDepartmentTable && departmentTechnicalApprovers.length > 0">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addNewTechAppr(this.businessUnitDepartments)"
        >
          + technical approver
        </button>

        <button
        type="button"
        class="btn btn-primary removeAdmin"
        (click)="removeTechnicalApprover('department')"
        *ngIf="departmentTechnicalApprovers.length > 0"
      >
        - technical approver
      </button>
      </div>
      <div class="errorMessage" *ngIf="businessUnitDepartments.length == 0">
        <h3> Oops! Looks like there are no departments setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
      </div>
      </div>
    <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="departmentTechnicalApprovers.length > 0 && showDepartmentTable">
      <app-user-table [data]="departmentTechnicalApprovers" [isDepartment]="validateApprovers(departmentTechnicalApprovers)" [businessUnitCode]="buSelected">
      </app-user-table>
    </div>
    <div class="noApproversContainer"  *ngIf="departmentTechnicalApprovers.length == 0 && businessUnitDepartments.length > 0 && showEmptyMessage">
      <div class="noApproversLabel">
        <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
      </div>
      <div class="noApproversButton">
        <button
        type="button"
        class="btn btn-primary"
        (click)="addNewTechAppr(this.businessUnitDepartments)"
      >
        + technical approver
      </button>
      </div>
    </div>
  </div>

    <!-- Team Container -->
    <div class="teamTechnicalApproversContainer" *ngIf="showTeamTechnicalApprovers">
    <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
      <div class="w-100" *ngIf="!showTeamTable && businessUnitTeams.length > 0  && teamTechnicalApprovers.length != 0">
        <div
          class="d-flex justify-content-center align-items-center"
          style="min-height: 200px"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <div class="techAprHeader" *ngIf="businessUnitTeams.length > 0 && showTeamTable && teamTechnicalApprovers.length > 0">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addNewTechAppr(this.businessUnitTeams)"
        >
          + technical approver
        </button>

        <button
        type="button"
        class="btn btn-primary removeAdmin"
        (click)="removeTechnicalApprover('team')"
        *ngIf="teamTechnicalApprovers.length > 0"
      >
        - technical approver
      </button>
      </div>
      <div class="errorMessage" *ngIf="businessUnitTeams.length == 0">
        <h3> Oops! Looks like there are no teams setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
      </div>
      </div>
    <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="teamTechnicalApprovers.length > 0 && showTeamTable">
      <app-user-table [data]="teamTechnicalApprovers" [isTeam]="validateApprovers(teamTechnicalApprovers)" [businessUnitCode]="buSelected">
      </app-user-table>
    </div>
    <div class="noApproversContainer"  *ngIf="teamTechnicalApprovers.length == 0 && businessUnitTeams.length > 0 && showEmptyMessage">
      <div class="noApproversLabel">
        <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
      </div>
      <div class="noApproversButton">
        <button
        type="button"
        class="btn btn-primary"
        (click)="addNewTechAppr(this.businessUnitTeams)"
      >
        + technical approver
      </button>
      </div>
    </div>
  </div>

    <!-- Related Process Container -->
    <div class="relatedProcessTechnicalApproversContainer" *ngIf="showRelatedProcessTechnicalApprovers">
    <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
      <div class="w-100" *ngIf="!showRelatedProcessTable && businessUnitRelatedProcesses.length > 0  && relatedProcessTechnicalApprovers.length != 0">
        <div
          class="d-flex justify-content-center align-items-center"
          style="min-height: 200px"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <div class="techAprHeader" *ngIf="businessUnitRelatedProcesses.length > 0 && showRelatedProcessTable && relatedProcessTechnicalApprovers.length > 0">
        <button
          type="button"
          class="btn btn-primary"
          (click)="addNewTechAppr(this.businessUnitRelatedProcesses)"
        >
          + technical approver
        </button>

        <button
        type="button"
        class="btn btn-primary removeAdmin"
        (click)="removeTechnicalApprover('relatedProcess')"
        *ngIf="relatedProcessTechnicalApprovers.length > 0"
      >
        - technical approver
      </button>
      </div>
      <div class="errorMessage" *ngIf="businessUnitRelatedProcesses.length == 0">
        <h3> Oops! Looks like there are no related processes setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
      </div>
      </div>
    <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="relatedProcessTechnicalApprovers.length > 0 && showRelatedProcessTable">
      <app-user-table [data]="relatedProcessTechnicalApprovers" [isTeam]="validateApprovers(relatedProcessTechnicalApprovers)" [businessUnitCode]="buSelected">
      </app-user-table>
    </div>
    <div class="noApproversContainer"  *ngIf="relatedProcessTechnicalApprovers.length == 0 && businessUnitRelatedProcesses.length > 0 && showEmptyMessage">
      <div class="noApproversLabel">
        <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
      </div>
      <div class="noApproversButton">
        <button
        type="button"
        class="btn btn-primary"
        (click)="addNewTechAppr(this.businessUnitRelatedProcesses)"
      >
        + technical approver
      </button>
      </div>
    </div>
  </div>

      <!-- Asset Container -->
      <div class="assetTechnicalApproversContainer" *ngIf="showAssetTechnicalApprovers">
        <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
          <div class="w-100" *ngIf="!showAssetTable && businessUnitAssets.length > 0  && assetTechnicalApprovers.length != 0">
            <div
              class="d-flex justify-content-center align-items-center"
              style="min-height: 200px"
            >
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <div class="errorMessage" *ngIf="businessUnitAssets.length == 0">
            <h3> Oops! Looks like there are no assets setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
          </div>
          <div class="techAprHeader" *ngIf="businessUnitAssets.length > 0  && showAssetTable && assetTechnicalApprovers.length > 0">
            <button
              type="button"
              class="btn btn-primary"
              (click)="addNewTechAppr(this.businessUnitAssets)"
            >
              + technical approver
            </button>
    
            <button
            type="button"
            class="btn btn-primary removeAdmin"
            (click)="removeTechnicalApprover('asset')"
            *ngIf="assetTechnicalApprovers.length > 0"
          >
            - technical approver
          </button>
          </div>
          </div>
        <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="assetTechnicalApprovers.length > 0 && showAssetTable">
          <app-user-table [data]="assetTechnicalApprovers" [isAsset]="validateApprovers(assetTechnicalApprovers)" [businessUnitCode]="buSelected">
          </app-user-table>
        </div>
        <div class="noApproversContainer"  *ngIf="assetTechnicalApprovers.length == 0 && businessUnitAssets.length > 0 && showEmptyMessage">
          <div class="noApproversLabel">
            <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
          </div>
          <div class="noApproversButton">
            <button
            type="button"
            class="btn btn-primary"
            (click)="addNewTechAppr(this.businessUnitAssets)"
          >
            + technical approver
          </button>
          </div>
        </div>
      </div>

    <!-- CoP Container -->
    <div class="copTechnicalApproversContainer" *ngIf="showCopTechnicalApprovers">
    <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
      <div class="w-100" *ngIf="!showCopTable && businessUnitCops.length > 0 && copTechnicalApprovers.length != 0">
        <div
          class="d-flex justify-content-center align-items-center"
          style="min-height: 200px"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
      <div class="techAprHeader" *ngIf="businessUnitCops.length > 0 && showCopTable && copTechnicalApprovers.length > 0">
        <button
          type="button"
          class="btn btn-primary addAdmin"
          (click)="addNewTechAppr(this.businessUnitCops)"
        >
          + technical approver
        </button>

        <button
        type="button"
        class="btn btn-primary removeAdmin"
        (click)="removeTechnicalApprover('cop')"
        *ngIf="copTechnicalApprovers.length > 0"
      >
        - technical approver
      </button>
      </div>
      <div class="errorMessage" *ngIf="businessUnitCops.length == 0">
        <h3> Oops! Looks like there are no cops setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
      </div>
      </div>
    <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="copTechnicalApprovers.length > 0 && showCopTable">
      <app-user-table [data]="copTechnicalApprovers" [isCop]="validateApprovers(copTechnicalApprovers)" [businessUnitCode]="buSelected">
      </app-user-table>
    </div>
    <div class="noApproversContainer"  *ngIf="copTechnicalApprovers.length == 0 && businessUnitCops.length > 0 && showEmptyMessage">
      <div class="noApproversLabel">
        <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
      </div>
      <div class="noApproversButton">
        <button
        type="button"
        class="btn btn-primary"
        (click)="addNewTechAppr(this.businessUnitCops)"
      >
        + technical approver
      </button>
      </div>
    </div>
  </div>

      <!-- CPP Region Container -->
      <div class="cppRegionTechnicalApproversContainer" *ngIf="showCPPRegionTechnicalApprovers">
        <div class="d-flex flex-row" style="gap: 1rem;padding-bottom: 2rem">
          <div class="w-100" *ngIf="!showCPPRegionTable && businessUnitCPPRegions.length > 0 && cppRegionTechnicalApprovers.length != 0">
            <div
              class="d-flex justify-content-center align-items-center"
              style="min-height: 200px"
            >
              <mat-spinner></mat-spinner>
            </div>
          </div>
          <div class="techAprHeader" *ngIf="businessUnitCPPRegions.length > 0 && showCPPRegionTable && cppRegionTechnicalApprovers.length > 0">
            <button
              type="button"
              class="btn btn-primary addAdmin"
              (click)="addNewTechAppr(this.businessUnitCPPRegions)"
            >
              + technical approver
            </button>
    
            <button
            type="button"
            class="btn btn-primary removeAdmin"
            (click)="removeTechnicalApprover('region')"
            *ngIf="cppRegionTechnicalApprovers.length > 0"
          >
            - technical approver
          </button>
          </div>
          <div class="errorMessage" *ngIf="businessUnitCPPRegions.length == 0">
            <h3> Oops! Looks like there are no regions setup for your given business unit. If this is a mistake - please reach out to the support team <a href="{{ contentContactUrl }}">here</a>.</h3>
          </div>
          </div>
        <div class="mt-4 techAprList" styler="padding-bottom: 2rem" *ngIf="cppRegionTechnicalApprovers.length > 0 && showCPPRegionTable">
          <app-user-table [data]="cppRegionTechnicalApprovers" [isRegion]="validateApprovers(cppRegionTechnicalApprovers)" [businessUnitCode]="buSelected">
          </app-user-table>
        </div>
        <div class="noApproversContainer"  *ngIf="cppRegionTechnicalApprovers.length == 0 && businessUnitCPPRegions.length > 0 && showEmptyMessage">
          <div class="noApproversLabel">
            <h3> Looks like there aren't any technical approvers setup just yet :/ </h3>
          </div>
          <div class="noApproversButton">
            <button
            type="button"
            class="btn btn-primary"
            (click)="addNewTechAppr(this.businessUnitCPPRegions)"
          >
            + technical approver
          </button>
          </div>
        </div>
      </div>
</div>

